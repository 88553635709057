<template>
  <footer class="footer-container">
    <div class="footer-content">
      <!-- Logo et slogan -->
      <div class="footer-branding">
        <div class="footer-logo">
          <img src="../assets/img/LogoHRWBlanc.png" alt="Logo" />
          <!-- <span class="logo-text">J-M</span> -->
        </div>
        <p class="footer-slogan">Développeur Web & Designer Graphique</p>
      </div>

      <!-- Navigation rapide -->
      <div class="footer-navigation">
        <h3 class="footer-heading">Navigation</h3>
        <ul class="footer-links">
          <li v-for="(item, index) in navItems" :key="index">
            <a :href="item.href" @click.prevent="scrollToSection(item.href)">
              {{ item.text }}
            </a>
          </li>
        </ul>
      </div>

      <!-- Contact -->
      <div class="footer-contact">
        <h3 class="footer-heading">Contact</h3>
        <ul class="contact-list">
          <li>
            <i class="fas fa-envelope"></i>
            <a href="mailto:harrowjeanmichel@gmail.com">harrowjeanmichel@gmail.com</a>
          </li>
          <li>
            <i class="fas fa-phone"></i>
            <span>+596 696 67 00 71</span>
          </li>
          <li>
            <i class="fas fa-map-marker-alt"></i>
            <span>IUT de la Martinique</span>
          </li>
        </ul>
      </div>

      <!-- Réseaux sociaux -->
      <div class="footer-social">
        <h3 class="footer-heading">Réseaux sociaux</h3>
        <div class="social-icons">
          <a href="https://github.com/Harrow973" target="_blank" rel="noopener noreferrer" class="social-icon" title="GitHub" aria-label="GitHub">
            <i class="fab fa-github"></i>
          </a>
          <a href="https://www.linkedin.com/in/jean-michel-harrow-529b47266/" target="_blank" rel="noopener noreferrer" class="social-icon" title="LinkedIn" aria-label="LinkedIn">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>

    <!-- Ligne de séparation -->
    <div class="footer-divider"></div>

    <!-- Copyright et crédits -->
    <div class="footer-bottom">
      <p class="copyright">© {{ currentYear }} Jean-Michel Harrow. Tous droits réservés.</p>
      <p class="credits">Conçu et développé avec <i class="fas fa-heart">Vue.js</i></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      navItems: [
        { text: 'Accueil', href: '#accueil' },
        { text: 'Réalisations', href: '#realisations' },
        { text: 'Compétences', href: '/Skills' },
        { text: 'Contact', href: '#contact' }
      ]
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    scrollToSection(href) {
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
.footer-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: #e0e0e0;
  padding: 4rem 2rem 2rem;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.4);
}

.footer-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #FFD700, #FFA500);
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-branding {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  margin-bottom: 1rem;
}

.logo-text {
  font-size: 1.4rem;
  font-weight: 700;
  background: linear-gradient(90deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #FFA500;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-slogan {
  color: #a0a0a0;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.footer-heading {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  position: relative;
  color: #ffffff;
}

.footer-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 30px;
  height: 2px;
  background: linear-gradient(90deg, #FFD700, #FFA500);
}

.footer-links, .contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li, .contact-list li {
  margin-bottom: 0.8rem;
}

.footer-links a, .contact-list a {
  color: #a0a0a0;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.9rem;
}

.footer-links a:hover, .contact-list a:hover {
  color: #FFA500;
}

.contact-list li {
  display: flex;
  align-items: center;
}

.contact-list i {
  margin-right: 0.8rem;
  color: #FFA500;
  font-size: 0.9rem;
}

.contact-list span {
  color: #a0a0a0;
  font-size: 0.9rem;
}

.social-icons {
  display: flex;
  gap: 0.8rem;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.social-icon:hover {
  transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFA500;
  border-color: rgba(255, 255, 255, 0.3);
}

.footer-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2rem auto;
  max-width: 1200px;
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 0.85rem;
}

.copyright {
  color: #777;
}

.credits {
  color: #777;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.fa-heart {
  color: #FFA500;
  font-size: 0.8rem;
}

.footer-navigation {
  text-align: left;
}

.footer-navigation .footer-heading,
.footer-navigation .footer-links {
  text-align: left;
}

.footer-navigation .footer-heading::after {
  left: 0;
  transform: none;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-branding {
    align-items: center;
  }

  .footer-heading::after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Garder la navigation alignée à gauche même sur mobile */
  .footer-navigation {
    text-align: left;
  }
  
  .footer-navigation .footer-heading,
  .footer-navigation .footer-links {
    text-align: left;
  }
  
  .footer-navigation .footer-heading::after {
    left: 0;
    transform: none;
  }

  .contact-list li {
    flex-direction: column;
    gap: 0.3rem;
  }

  .contact-list i {
    margin-right: 0;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 0.8rem;
    text-align: center;
  }
}
</style>