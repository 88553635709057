<template>
    <Skills />
</template>

<script>
import Skills from '@/components/Skills.vue'


export default {
  name: 'SkillsPage',
  components: {
    Skills
  }
}
</script>

<style scoped>
#particles-container {
  position: absolute;
  top: 20;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style> 