<template>
  <div class="skills-container">
    <h1 class="mt-20 title-skills">Compétences et Apprentissages Critiques</h1>
    
    <div class="skills-grid">
      <div v-for="(skill, index) in skills" :key="index" class="skill-category mt-10">
        <h2 :class="getSkillColor(index)">{{ skill.category }}</h2>
        <div class="skill-items">
          <div v-for="(item, itemIndex) in skill.items" :key="itemIndex" class="skill-item">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillPage',
  data() {
    return {
      skills: [
        {
          category: 'Réaliser un développement d\'application',
          items: [
            'Implémenter des conceptions simples',
            'Élaborer des conceptions simples',
            'Faire des essais et évaluer leur résultat en regard des spécifications',
            'Développer des interfaces utilisateur'
          ]
        },
        {
          category: 'Partir des exigences et aller jusqu\'à une application complète',
          items: [
            'Élaborer et implémenter les spécifications fonctionnelles et non fonctionnelles à partir des exigences',
            'Appliquer des principes d\'accessibilité et d\'ergonomie',
            'Adopter des bonnes pratiques de conception et de programmation',
            'Vérifier et valider la qualité de l\'application par les tests'
          ]
        },
        {
          category: 'Optimiser des applications',
          items: [
            'Analyser un problème avec méthode de découpage en éléments algorithmiques simples, structure de données',
            'Comparer des algorithmes pour des problèmes classiques (tri simple, recherche...)',
            'Formaliser et mettre en œuvre des outils mathématiques pour l\'informatique'
          ]
        },
        {
          category: 'Sélectionner les algorithmes adéquats pour répondre à un problème donné',
          items: [
            'Choisir des structures de données complexes adaptées au problème',
            'Utiliser des technologies algorithmiques adaptées pour des problèmes complexes (recherches opérationnelles, méthodes arborescentes, optimisation globale, IA...)',
            'Évaluer l\'impact environnemental et sociétal des solutions proposées'
          ]
        },
        {
          category: 'Administrer des systèmes informatiques communicants complexes',
          items: [
            'Identifier les différents composants (matériel et logiciel d\'un système numérique)',
            'Utiliser les conformités de base d\'un système multitâche/multiutilisateur',
            'Installer et configurer un système d\'exploitation et des outils de développement',
            'Configurer un poste de travail dans un réseau d\'entreprise'
          ]
        },
        {
          category: 'Déployer des services dans une architecture',
          items: [
            'Concevoir et développer des applications communicantes',
            'Utiliser des serveurs et des services (réseaux virtualisés)',
            'Sécuriser les services et données d\'un système'
          ]
        },
        {
          category: 'Gérer des données de l\'information',
          items: [
            'Mettre à jour et interroger une base de données relationnelle (en requête directe ou à travers un application)',
            'Visualiser des données',
            'Concevoir une base de données relationnelle à partir d\'un cahier des charges'
          ]
        },
        {
          category: 'Optimiser une base de données, intéragir avec une application et mettre en œuvre la sécurité',
          items: [
            'Optimiser les modèles de données de l\'entreprise',
            'Assurer la confidentialité des données (intégrité et sécurité)',
            'Organiser la restitution de données à travers la programmation et la visualisation',
            'Manipuler des données hétérogènes'
          ]
        },
        {
          category: 'Conduire un projet',
          items: [
            'Appréhender les besoins du client et de l\'utilisateur',
            'Mettre en place les outils de gestion de projet',
            'Identifier les acteurs et les différentes phases d\'un cycle de développement'
          ]
        },
        {
          category: 'Appliquer une démarche de suivi de projet en fonction des besoins métiers des clients et des utilisateurs',
          items: [
            'Identifier les processus présents dans une organisation en vue d\'améliorer les systèmes d\'information',
            'Formaliser les besoins du client et de l\'utilisateur',
            'Identifier les critères de faisabilité d\'un projet informatique',
            'Définir et mettre en œuvre une démarche de suivi de projet'
          ]
        },
        {
          category: 'Collaborer au sein d\'une équipe informatique',
          items: [
            'Appréhender l\'écosystème numérique',
            'Découvrir les aptitudes requises selon les différents secteurs informatiques',
            'Identifier les statuts, les fonctions et les rôles de chaque membre d\'une équipe pluridisciplinaire',
            'Acquérir les compétences interpersonnelles pour travailler en équipe'
          ]
        },
        {
          category: 'Situer son rôle et ses missions au sein d\'une organisation',
          items: [
            'Comprendre la diversité et la dimension de l\'informatique dans une organisation (ENS, DSI...)',
            'Appliquer une démarche pour intégrer une équipe informatique au sein d\'une organisation',
            'Mobiliser les compétences interpersonnelles pour intégrer une équipe informatique',
            'Rendre compte de son activité professionnelle'
          ]
        }
      ]
    };
  },
  methods: {
    getSkillColor(index) {
      // Apply different colors based on category index
      const colorClasses = [
        'skill-blue', 'skill-light-blue', 'skill-orange', 'skill-light-orange',
        'skill-blue', 'skill-light-blue', 'skill-yellow', 'skill-light-yellow',
        'skill-blue', 'skill-light-blue', 'skill-green', 'skill-light-green'
      ];
      return colorClasses[index % colorClasses.length];
    }
  }
};
</script>

<style scoped>
.skills-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.skill-category {
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.skill-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.skill-category h2 {
  padding: 15px;
  margin: 0;
  color: white;
  font-weight: 600;
  font-size: 1.1em;
  text-align: center;
  display: flex;
  align-items: center;
  min-height: 80px;
}

.skill-items {
  background-color: #f9f9f9;
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
}

.skill-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;
}

.skill-item:hover {
  background-color: #f5f5f5;
}

.title-skills {
  font-size: xx-large;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

/* Category color classes */
.skill-blue {
  background-color: #3498db;
}

.skill-light-blue {
  background-color: #5dade2;
}

.skill-orange {
  background-color: #e67e22;
}

.skill-light-orange {
  background-color: #f39c12;
}

.skill-yellow {
  background-color: #f1c40f;
}

.skill-light-yellow {
  background-color: #f4d03f;
}

.skill-green {
  background-color: #2ecc71;
}

.skill-light-green {
  background-color: #58d68d;
}

@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }
  
  .skills-container {
    padding: 10px;
  }
  
  .skill-category h2 {
    font-size: 1.1em;
    min-height: 60px;
  }
}
</style>