<template>
  <div>
    <div id="particles-container"></div>
    <Hero />
    <realisations />
    <Projets />
    <Contact />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import realisations from '@/components/Realisations.vue'
import Projets from '@/components/Projets.vue'

export default {
  name: 'HomePage',
  components: {
    Hero,
    realisations,
    Projets
  }
}
</script>

<style scoped>
#particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style>
