<template>
  <nav class="navbar-container" :class="{ 'navbar-scrolled': scrolled, 'mobileMenuOpen': mobileMenuOpen }">
    <div class="navbar-logo" @click="scrollToTop">
      <!-- <img src="../assets/img/LogoHRWBlanc.png" alt="Logo" /> -->
      <span class="logo-text">J-M</span>
    </div>
    
    <div class="navbar-links" :class="{ 'active': mobileMenuOpen }">
      <a 
        v-for="(item, index) in navItems" 
        :key="index" 
        :href="item.href" 
        class="nav-link nav-item"
        @click="handleNavClick($event, item.href)"
      >
        {{ item.text }}
        <span class="nav-link-underline"></span>
      </a>
      
      <!-- Bouton de téléchargement CV -->
      <a href="/CV-HARROW.pdf" download class="download-cv-btn nav-item">
        <i class="fas fa-download"></i> Télécharger CV
      </a>
      
      <!-- Icons sociaux pour mobile uniquement -->
      <div class="mobile-social">
        <a href="https://github.com/Harrow973" target="_blank" rel="noopener noreferrer" class="social-icon" title="GitHub">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://www.linkedin.com/in/jean-michel-harrow-529b47266/" target="_blank" rel="noopener noreferrer" class="social-icon" title="LinkedIn">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <a href="mailto:harrowjeanmichel@gmail.com" class="social-icon" title="Email">
          <i class="fas fa-envelope"></i>
        </a>
        <!-- Bouton de téléchargement CV pour mobile -->
        <a href="/CV-HARROW.pdf" download class="social-icon download-icon" title="Télécharger CV">
          <i class="fas fa-download"></i>
        </a>
      </div>
    </div>

    <div class="navbar-social">
      <a href="https://github.com/Harrow973" target="_blank" rel="noopener noreferrer" class="social-icon" title="GitHub">
        <i class="fab fa-github"></i>
      </a>
      <a href="https://www.linkedin.com/in/jean-michel-harrow-529b47266/" target="_blank" rel="noopener noreferrer" class="social-icon" title="LinkedIn">
        <i class="fab fa-linkedin-in"></i>
      </a>
      <a href="mailto:contact@jeanmichelharrow.fr" class="social-icon" title="Email">
        <i class="fas fa-envelope"></i>
      </a>
    </div>
    
    <div class="mobile-toggle" @click="toggleMobileMenu($event)">
      <span></span>
      <span></span>
      <span></span>
    </div>
    
    <!-- Overlay pour fermer le menu mobile en cliquant à l'extérieur -->
    <div v-if="mobileMenuOpen" class="mobile-menu-overlay" @click="closeMobileMenu"></div>
  </nav>
</template>

<script>
// Ajout de l'importation FontAwesome si vous utilisez la méthode d'importation avec npm
// Si vous avez installé via npm: @fortawesome/fontawesome-free
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  name: 'NavBar',
  data() {
    return {
      scrolled: false,
      mobileMenuOpen: false,
      navItems: [
        { text: 'Accueil', href: '#accueil' },
        { text: 'Réalisations', href: '#realisations' },
        // { text: 'Moi', href: '#moi' },
        { text: 'Compétences', href: '/skills' },
        { text: 'En cours', href: '#projets' }
      ]
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('click', this.closeMenuOnClickOutside);
    // Bloquer le défilement lors de l'ouverture du menu mobile
    this.$watch('mobileMenuOpen', (isOpen) => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.closeMenuOnClickOutside);
    // Restaurer le défilement
    document.body.style.overflow = '';
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleNavClick(event, href) {
      event.preventDefault();
      
      // Ferme le menu mobile si ouvert
      this.mobileMenuOpen = false;
      
      // Si c'est un lien vers une route (commence par '/')
      if (href.startsWith('/')) {
        // Naviguer vers la route avec vue-router
        this.$router.push(href);
      } else if (href.startsWith('#')) {
        // Vérifier si nous sommes déjà sur la page d'accueil
        if (this.$route.path !== '/') {
          // Si non, naviguer vers la page d'accueil d'abord
          this.$router.push('/').then(() => {
            // Attendre un peu pour que la page se charge
            setTimeout(() => {
              const element = document.querySelector(href);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 300);
          });
        } else {
          // Nous sommes déjà sur la page d'accueil, juste faire défiler
          const element = document.querySelector(href);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    },
    toggleMobileMenu(event) {
      event.stopPropagation();
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    closeMenuOnClickOutside(event) {
      // Corrigé pour utiliser les sélecteurs corrects
      const navbarLinks = document.querySelector('.navbar-links');
      const mobileToggle = document.querySelector('.mobile-toggle');
      
      if (this.mobileMenuOpen && 
          navbarLinks && 
          !navbarLinks.contains(event.target) && 
          !mobileToggle.contains(event.target)) {
        this.mobileMenuOpen = false;
      }
    }
  }
};
</script>

<style scoped>
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  transition: all 0.4s ease;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar-scrolled {
  padding: 0.5rem 2rem;
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-text {
  font-size: 1.7rem;
  font-weight: 700;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -1px;
  border: 2px solid #FFA500;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.navbar-logo:hover .logo-text {
  transform: scale(1.05);
}

.navbar-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #FFA500;
}

.nav-link-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #FFD700, #FFA500);
  transition: width 0.3s ease;
}

.nav-link:hover .nav-link-underline {
  width: 100%;
}

.navbar-social {
  display: flex;
  gap: 1rem;
}

.social-icon {
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.social-icon:hover {
  color: #FFA500;
  transform: translateY(-3px);
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}

.mobile-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.mobile-toggle span {
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #FFD700, #FFA500);
  border-radius: 10px;
  transition: all 0.3s linear;
}

/* Styles pour les appareils mobiles */
@media (max-width: 768px) {
  .navbar-links {
    position: fixed;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(15px);
    top: 0;
    right: -100%; /* Initialement hors écran */
    width: 80%;
    height: 100vh;
    padding: 6rem 2rem 2rem;
    gap: 1.5rem;
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 50;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    overflow-y: auto; /* Permettre le défilement si beaucoup d'éléments */
    box-shadow: -5px 0 25px rgba(0, 0, 0, 0.5);
  }

  .navbar-links.active {
    right: 0; /* Affiché quand active */
  }
  
  .mobile-toggle {
    display: flex;
    z-index: 60;
  }
  
  .navbar-links a {
    width: 100%;
    text-align: left;
    padding: 0.8rem 0;
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-container {
    padding: 1rem;
  }

  .navbar-social {
    display: none; /* Masquer sur mobile */
  }
  
  /* Menu mobile social links */
  .mobile-social {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .mobile-social .social-icon {
    width: 45px;
    height: 45px;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.05);
  }
  
  /* Overlay pour fermer le menu en cliquant à l'extérieur */
  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 40;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
  }
}

/* En dehors du media query, nous nous assurons que ces éléments sont correctement configurés pour desktop */
.mobile-toggle {
  display: none; /* Masqué par défaut (desktop) */
}

.mobile-social {
  display: none; /* Masqué par défaut (desktop) */
}

.mobile-menu-overlay {
  display: none; /* Masqué par défaut */
}

@media (max-width: 768px) {
  .mobile-menu-overlay {
    display: block; /* Affiché sur mobile quand menu ouvert */
  }
}

/* Animation du menu hamburger */
.mobileMenuOpen .mobile-toggle span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.mobileMenuOpen .mobile-toggle span:nth-child(2) {
  opacity: 0;
}

.mobileMenuOpen .mobile-toggle span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Animations personnalisées */
@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.nav-animation {
  animation: fadeInDown 0.5s forwards;
}

/* Ajouter fade-in aux éléments de navigation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.nav-item {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.nav-item:nth-child(1) { animation-delay: 0.1s; }
.nav-item:nth-child(2) { animation-delay: 0.2s; }
.nav-item:nth-child(3) { animation-delay: 0.3s; }
.nav-item:nth-child(4) { animation-delay: 0.4s; }
.nav-item:nth-child(5) { animation-delay: 0.5s; }
.nav-item:nth-child(6) { animation-delay: 0.6s; }

@keyframes slideIn {
  from { transform: translateX(30px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@media (max-width: 768px) {
  .navbar-links.active .nav-item {
    animation: slideIn 0.4s forwards;
  }
  
  .navbar-links.active .nav-item:nth-child(1) { animation-delay: 0.1s; }
  .navbar-links.active .nav-item:nth-child(2) { animation-delay: 0.15s; }
  .navbar-links.active .nav-item:nth-child(3) { animation-delay: 0.2s; }
  .navbar-links.active .nav-item:nth-child(4) { animation-delay: 0.25s; }
  .navbar-links.active .nav-item:nth-child(5) { animation-delay: 0.3s; }
  .navbar-links.active .nav-item:nth-child(6) { animation-delay: 0.35s; }
  
  .navbar-links.active .mobile-social {
    animation: fadeIn 0.5s 0.4s forwards;
    opacity: 0;
  }
}

/* Style pour le bouton de téléchargement CV */
.download-cv-btn {
  background: linear-gradient(to right, #FFD700, #FFA500);
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  box-shadow: 0 4px 15px rgba(255, 165, 0, 0.3);
}

.download-cv-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 165, 0, 0.4);
  color: #000; /* Garde la couleur noire même au survol */
}

.download-cv-btn i {
  font-size: 1rem;
}

/* Style pour le bouton de téléchargement dans le menu mobile */
.download-icon {
  background: linear-gradient(to right, #FFD700, #FFA500) !important;
  color: #000 !important;
}

.download-icon:hover {
  transform: translateY(-3px) !important;
  color: #000 !important;
}

/* Ajustements pour le responsive */
@media (max-width: 768px) {
  .download-cv-btn {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }
  
  /* ... autres règles media existantes ... */
}
</style>
