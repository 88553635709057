<template>
  <section id="projets" class="py-20 bg-black">
    <div class="container mx-auto px-4">
      <!-- En-tête de section -->
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold text-white mb-4">
          Projets en <span class="text-gradient">développement</span>
        </h2>
        <p class="text-gray-300 max-w-2xl mx-auto">
          Découvrez mes projets actuellement en cours de développement et ceux
          en phase de planification.
        </p>
      </div>

      <!-- Grille de projets -->
      <div class="masonry-grid">
        <div
          v-for="(projet, index) in projets"
          :key="index"
          class="masonry-item mb-8"
        >
          <div class="projet-card overflow-hidden shadow-xl">
            <!-- Bannière de statut -->
            <div class="category-ribbon">{{ projet.statut }}</div>

            <!-- Image du projet avec overlay -->
            <div class="projet-image-container">
              <img :src="projet.image" :alt="projet.titre" class="projet-img" />
              <div class="projet-overlay">
                <div class="overlay-actions">
                  <a
                    v-if="projet.demo"
                    :href="projet.demo"
                    target="_blank"
                    class="action-btn demo-btn"
                  >
                    <i class="fas fa-eye mr-2"></i>Voir la démo
                  </a>
                  <a
                    v-if="projet.github"
                    :href="projet.github"
                    target="_blank"
                    class="action-btn code-btn"
                  >
                    <i class="fab fa-github mr-2"></i>GitHub
                  </a>
                </div>
              </div>
            </div>

            <!-- Contenu du projet -->
            <div class="projet-content">
              <h3 class="projet-title">{{ projet.titre }}</h3>
              
              <div class="mb-4">
                <p 
                  class="projet-description" 
                  :class="{ 'line-clamp-3': !projet.showFullDescription }"
                >
                  {{ projet.description }}
                </p>
                <button 
                  v-if="projet.description.length > 150" 
                  @click="toggleDescription(projet)"
                  class="read-more-btn"
                >
                  {{ projet.showFullDescription ? 'Voir moins' : 'Voir plus' }}
                  <i 
                    class="fas ml-1 text-xs" 
                    :class="[projet.showFullDescription ? 'fa-chevron-up' : 'fa-chevron-down']"
                  ></i>
                </button>
              </div>

              <!-- Technologies utilisées -->
              <div class="tech-badges">
                <span
                  v-for="(tech, techIndex) in projet.technologies"
                  :key="techIndex"
                  class="tech-badge"
                >
                  {{ tech }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProjetsPage",
  data() {
    return {
      projets: [
        {
          titre: "Boutique Calypso",
          description:
            "Dans le cadre de mon devoir en communication, je suis chargé de créer un site internet pour la boutique Calypso. Spécialisée dans la vente de bikinis, de vêtements de resort et d'articles de décoration intérieure en provenance de Bali et du Maroc. Pour ce projet, je m'appuierai sur le contenu et l'esthétique de leur page Instagram (@calypso.mq) afin de développer une plateforme web conviviale qui reflète l'identité visuelle de la boutique. Le site présentera les produits disponibles, fournira des informations pratiques telles que les horaires d'ouverture et l'adresse, et intégrera des fonctionnalités pour améliorer l'expérience utilisateur.",
          image: require("../assets/logo.png"),
          statut: "   En cours  ",
          technologies: ["Vue.js", "Node.js", "Tailwind CSS"],
          github: null,
          demo: "https://v1calypso.netlify.app",
          showFullDescription: false,
        },
        {
          titre: "Association Doucine",
          description:
            "Je suis chargé du développement du site internet de l'association DOUCINE. Ce site aura pour objectif de mettre en avant les actions de l'association visant à renforcer le lien social et le bien-être des jeunes retraités, des seniors et des familles. Mon rôle consiste à concevoir une plateforme intuitive, accessible et moderne, permettant aux membres et au public de s'informer sur les événements, services et initiatives de DOUCINE.",
          image: require("../assets/LogoDoucine.png"),
          statut: "En cours",
          technologies: ["Vue.js", "Node.js", "Tailwind CSS"],
          github: null,
          demo: "https://v1doucine.netlify.app",
          showFullDescription: false,
        },
        {
          titre: "Harrow&Co",
          description:
            "Un site vitrine pour Harrow&co, spécialisée dans la création de sites web sur mesure. Le site présente nos services, notre portfolio de réalisations, et permet aux clients potentiels de nous contacter facilement. Développé avec Vue.js pour offrir une expérience utilisateur fluide et moderne.",
          image: require("../assets/LogoH&co.svg"),
          statut: "En cours",
          technologies: ["Vue.js", "Node.js", "Tailwind CSS"],
          github: null,
          demo: "https://harrowco.fr",
          showFullDescription: false,
        },
      ],
    };
  },
  methods: {
    toggleDescription(projet) {
      projet.showFullDescription = !projet.showFullDescription;
    }
  },
};
</script>

<style scoped>
.text-gradient {
  background: linear-gradient(to right, #ffd700, #ffa500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Grille Masonry */
.masonry-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
}

@media (min-width: 768px) {
  .masonry-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .masonry-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Cartes de projet */
.projet-card {
  position: relative;
  background: linear-gradient(145deg, #3a3d44, #4a4d54);
  border-radius: 16px;
  transition: all 0.4s ease;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.projet-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  border-color: rgba(255, 215, 0, 0.2);
}

/* Ruban de statut */
.category-ribbon {
  position: absolute;
  top: 20px;
  right: -30px;
  background: linear-gradient(to right, #ffd700, #ffa500);
  color: #1a1a1a;
  padding: 5px 30px;
  font-size: 0.75rem;
  font-weight: 600;
  transform: rotate(45deg);
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Image et overlay */
.projet-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projet-img {
  max-height: 85%;
  max-width: 85%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.projet-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.projet-card:hover .projet-img {
  transform: scale(1.05);
}

.projet-card:hover .projet-overlay {
  opacity: 1;
}

/* Actions d'overlay */
.overlay-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateY(20px);
  transition: transform 0.3s ease;
}

.projet-card:hover .overlay-actions {
  transform: translateY(0);
}

.action-btn {
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.demo-btn {
  background: linear-gradient(to right, #ffd700, #ffa500);
  color: #1a1a1a;
}

.demo-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.4);
}

.code-btn {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.code-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Contenu du projet */
.projet-content {
  padding: 1.5rem;
}

.projet-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  margin-bottom: 0.75rem;
  background: linear-gradient(to right, #fff, #e2e2e2);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.projet-description {
  color: #d1d5db;
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.read-more-btn {
  margin-top: 0.25rem;
  color: #ffd700;
  font-size: 0.85rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.read-more-btn:hover {
  color: #ffa500;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Badges de technologies */
.tech-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tech-badge {
  font-size: 0.7rem;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: #e2e8f0;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
